<template>
    <div class="c-404">
        <h1>Coming Soonn</h1>
    </div>
</template>

<script>
export default {
    name: 'error',
};
</script>
